import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Container, Grid } from '@material-ui/core';
import { ParallaxBanner } from 'react-scroll-parallax';
import CompareIcon from '@material-ui/icons/CompareArrows';
import UpdateIcon from '@material-ui/icons/Update';
import PriceIcon from '@material-ui/icons/LocalOffer';
import BypassIcon from '@material-ui/icons/Rotate90DegreesCcw';
import FeatureSectionLight from '../components/featureSectionLight';
import SubLayout from '../layouts/subLayout';
import ReactIcon from '../../assets/svg/react-original.svg';
import WorkboxIcon from '../../assets/svg/workbox.svg';
import NextIcon from '../../assets/svg/nextjs-plain.svg';
import AllServices from '../components/allServices';
import SEO from '../components/seo';

const PWAPage = () => {
  const data = useStaticQuery(graphql`
        query PWAImages {
            pwa: file(
                relativePath: {
                    eq: "aplikacje-progresywne-pwa.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pwaMain: file(
                relativePath: {
                    eq: "aplikacje-progresywne-pwa.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowaPWA: file(
                relativePath: {
                    eq: "kompleksowa-usluga.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <SubLayout
      title={(
        <>
          <span>Progressive</span>
          {' '}
          Web Apps
        </>
)}
      img="/img/apps-mobile-bg.jpg"
      subtitle="A modern alternative for mobile apps"
    >
      <SEO title="Progressive Web Apps (PWA) - iqcode Software House" description="We create cross-platform and engaging Progressive Web Apps (PWA) which are a modern alternative to traditional mobile applications." />
      <div style={{ boxShadow: '3px 3px 30px #00000070' }}>
        <FeatureSectionLight
          title={(
            <>
              Between
              {' '}
              <span>Mobile</span>
              {' '}
              Apps and
              {' '}
              <span>Websites</span>
            </>
                      )}
          img={<div style={{ padding: '0 14%' }}><Img alt="progressive web apps" fluid={data.pwaMain.childImageSharp.fluid} /></div>}
          technologies={[
            { icon: <ReactIcon />, name: 'React.js' },
            { icon: <WorkboxIcon />, name: 'Workbox' },
          ]}
          desc={(
            <>
              <p>
                Progressive Web Apps (PWAs) are a new type of digital products bridging the gap
                between mobile applications and websites. While PWAs run inside the web browser,
                they can be installed on the user's device and opened in fullscreen mode offering
                user experience similar to those of native mobile applications.
              </p>
              <p>
                PWAs have been introduced as a remedy for increasing reluctance of users to
                download new apps from App Store and Play Store. Since a progressive web app
                can be distributed via a plain link, it significantly decreases the user's entry
                barrier while retaining numerous functionalities typical of mobile apps.
              </p>
            </>
                      )}
        />
      </div>
      <div className="section" id="aplikacje-mobilne">
        <ParallaxBanner
          className="phone-showcase parallax-container bg-dark overlay-holder"
          style={{ height: 'auto', backgroundColor: '#b921cb4d' }}
          layers={[{
            image: '/img/p3.jpg',
            amount: 0.4,
            props: { style: { zIndex: -1 } },
          }]}
        >
          <div className="parallax" />
          <Container>
            <div className="row">
              <div className="col m12 l4">
                <div className="phone-block">
                  <CompareIcon />
                  <h4>Cross-platform</h4>
                  <p>
                    A single PWA can be installed Android and iOS smartphones, as well as on PCs and laptops.
                  </p>
                </div>
                <div className="phone-block">
                  <UpdateIcon />
                  <h4>Rapid development</h4>
                  <p>
                    Newer versions can be released instantly without awaiting the app store operator's approval.
                  </p>
                </div>
              </div>
              <div className="col m12 l4">
                <Img fluid={data.pwa.childImageSharp.fluid} alt="progressive web apps" />
              </div>
              <div className="col m12 l4">
                <div className="phone-block">
                  <PriceIcon />
                  <h4>Lower production costs</h4>
                  <p>
                    Since PWAs use web technologies, such projects are usually less expensive than
                    mobile apps of equivalent functionality.
                  </p>
                </div>
                <div className="phone-block">
                  <BypassIcon />
                  <h4>Bypass app store fee</h4>
                  <p>
                    Since PWAs are no distributed via app stores,
                    there is no commission on transactions completed within them.
                  </p>
                </div>
              </div>
            </div>
          </Container>
          <div className="diagonal-overlay-color" />
        </ParallaxBanner>
        <div className="technologies bg-dark outline-fix z-depth-3">
          <Container>
            <h3>
              <span>Technologies</span>
              {' '}
              that we use
            </h3>

            <Grid container>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <ReactIcon className="tech-icon" />
                <p>React.js</p>
              </Grid>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <WorkboxIcon className="tech-icon" />
                <p>Workbox</p>
              </Grid>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <NextIcon className="tech-icon" />
                <p>Next.js</p>
              </Grid>
            </Grid>
            <Link className="btn btn-alt" to="/technologie">Learn more</Link>
          </Container>
        </div>

        <FeatureSectionLight
          title={(
            <>
              <span>All-rounded </span>
              IT projects
            </>
                  )}
          mb0
          img={(
            <div style={{ padding: '10%' }}>
              <Img
                alt="IT projects delivery"
                fluid={data.kompleksowaPWA.childImageSharp.fluid}
                imgStyle={{ width: 'auto' }}
              />
            </div>
                  )}
          desc={(
            <>
              <p>
                Virtually all progressive web apps require and appropriate
                {' '}
                <Link to="/backend-development/">server-side application</Link>
                {' '}
                in order to deliver all of their functionalities.
              </p>
              <p>
                We deliver complex IT projects starting at the requirements engineering stage, through development works following
                {' '}
                <strong>agile</strong>
                {' '}
                principles and project consulting regarding the app's public start finishing off with rock-solid tech support, data-driven analytics and iterative evolution of the digital product.
              </p>
              <p>
                Our wide variety of mobile, web and server-side technologies allows us to deliver complex IT projects cross-cutting numerous software engineering areas devoid of technical debt.
              </p>
            </>
                  )}
          link="Let's talk"
          to="/contact/"
        />

        <AllServices />
      </div>
    </SubLayout>
  );
};

export default PWAPage;
